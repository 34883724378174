/* Background styling for text container */
#textcont-background {
	background: linear-gradient(to bottom, #10041c, #220e27, #10041c);
	animation: animate-background 40s infinite ease-in-out;
	min-height: 100vh;
	width: 100%;
  }
  
  /* Container for textual content */
  #textcont-container {
	width: 70%;
	margin: 0 auto;
	padding-top: 70px;
  }
  
  /* Title styling */
  #textcont-title {
	color: #d8d8d8;
	font-size: 35px;
	font-weight: 600;
	font-family: "SIMPLIFICA-2";
  }
  
  /* Sub-title styling */
  #textcont-sub-title {
	color: #d8d8d8;
	font-size: 30px;
	font-weight: 600;
	font-family: "SIMPLIFICA-2";
  }

/* Text-container styling */
#textcont-content {
	color: #d8d8d8;
	font-size: 27.5px;
	font-weight: 500;
	font-family: "SIMPLIFICA";
}

.animation,
.animation_layer {
	height: 100vh;
}

.animation {
	display: block;
	position: relative;
	z-index: 10;
	overflow: visible;
}

.animation > div {
	overflow: visible;
}

.animation_layer {
	background-position: bottom center;
	background-size: auto 1038px;
	background-repeat: repeat-x;
	width: 100%;
	overflow: auto;
	position: absolute;
}

.animation_layer.parallax {
	position: fixed;
}

/* General background styling */
#background {
	background: linear-gradient(to bottom, transparent, #10041c), url(../images/background.png);
	background-repeat: no-repeat;
	background-size: cover;
  }
  
  /* Logo styling */
  #logo {
	background-image: url(../images/logo.png);
	width: 300px;
	height: 300px;
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	top: 20%;
	left: 50%;
	transform: translateX(-50%);
  }
  
  /* Buttons container styling */
  #buttons {
	z-index: 10;
	padding: 55px;
	display: flex;
	justify-content: center;
	gap: 30px;
	position: absolute;
	top: 60%;
	left: 50%;
	transform: translateX(-50%);
  }
  
  #buttons a {
	display: block;
  }
  
  #buttons img {
	width: 50px;
	height: 50px;
  }
  
  /* Media Query for smaller screen sizes */
  @media (max-width: 620px) {
	#textcont-container {
	  padding-top: auto;
	  padding-bottom: auto;
	}
  
	#textcont-title {
	  font-size: 28px;
	}
  
	#textcont-sub-title {
	  font-size: 24px;
	}
  
	#textcont-content {
	  color: #d8d8d8;
	  font-size: 22px;
	}
  }
  